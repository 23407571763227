<template>
  <div class="card-body">
    <Form :service="service" :actions="[{ action: 'save' }]">
      <FormField
        label="Начало командировки"
        :service="service"
        field="dtStart"
        type="calendar"
      />
      <FormField
        label="Конец командировки"
        :service="service"
        field="dtEnd"
        type="calendar"
      />
    </Form>
  </div>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormField from "@/components/ui/form/FormField.vue";
import Str from "@/utils/Str";
import UserTripService from "../services/UserTripService";

export default {
  props: {
    userTripId: String,
  },
  components: {
    Form,
    FormField,
  },
  data() {
    return {
      service: new UserTripService(),
      userList: {},
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.userTripId);
  },
};
</script>
