import Service from '../../../services/Service';

class UserTripService extends Service {

    basePath = 'user-trip';

    newObject() {
        return new UserTripService();
    }
    
}

export default UserTripService;