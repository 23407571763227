<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Командировки', routeName: 'Trip' },
          { label: 'Редактирование' },
        ]"
      />
      <h4 class="page-title">Редактирование</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          redirectAfterSave="TripShow"
          :actions="[{ action: 'save', to: (data) => '/trip/' + data.id }]"
        >
          <FormField
            label="Начало командировки"
            :service="service"
            field="start"
            type="calendar"
          />
          <FormField
            label="Конец командировки"
            :service="service"
            field="end"
            type="calendar"
          />
          <FormField
            label="Куда"
            :service="service"
            field="where"
            type="text"
          />
          <div class="mb-2">
            <label for="">Сотрудники:</label>
            <div
              class="mb-1"
              v-for="employee in service.employeeListNew"
              :key="employee.id"
            >
              {{ employee.surname }} {{ employee.name }}
              {{ employee.second_name }} {{ Str.date(employee.dtStart) }} -
              {{ Str.date(employee.dtEnd) }}
              <div
                class="pi pi-pencil text-center text-warning cursor-pointer"
                style="width: 30px; font-size: 1rem !important"
                @click="showEditEmployee(employee)"
              ></div>
              <br />
            </div>
            <div
              class="pi pi-plus-circle text-center text-success cursor-pointer"
              style="width: 30px; font-size: 1rem !important"
              @click="showAddEmployee()"
            ></div>
          </div>
          <FormField
            label="Старший группы"
            :service="service"
            field="older"
            :options="userList"
            type="select"
          />
          <FormField
            label="Файл с отчетом"
            :service="service"
            field="reportFile"
            :curFileName="service.reportFileName"
            type="file"
          />
          <FormField
            label="Отчёт"
            :service="service"
            field="reportText"
            type="textarea"
          />
          <FormField
            label="Отчётность принята"
            :service="service"
            field="return"
            type="select"
            :options="[
              {
                id: 1,
                name: 'Да',
              },
              {
                id: 0,
                name: 'Нет',
              },
            ]"
          />
        </Form>
      </div>
      <Dialog
        :modal="true"
        :header="
          choosenEmployee.surname +
          ' ' +
          choosenEmployee.name +
          ' ' +
          choosenEmployee.second_name
        "
        v-model:visible="dialogEditDisplay"
      >
        <editEmployee :userTripId="choosenEmployee.id" />
      </Dialog>
      <Dialog
        header="Добавление сотрудника"
        :modal="true"
        v-model:visible="dialogAddDisplay"
      >
        <Form :service="userTripService" :actions="[{ action: 'save' }]">
          <FormField
            label="Сотрудник"
            :service="userTripService"
            field="userId"
            :options="userList"
            type="select"
          />
          <FormField
            label="Начало командировки"
            :service="userTripService"
            field="dtStart"
            type="calendar"
          />
          <FormField
            label="Конец командировки"
            :service="userTripService"
            field="dtEnd"
            type="calendar"
          />
        </Form>
      </Dialog>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Form from "@/components/ui/form/Form.vue";
import FormField from "@/components/ui/form/FormField.vue";
import Str from "@/utils/Str";
import TripService from "../services/TripService";
import UserService from "@/modules/user/services/UserService";
import UserTripService from "../services/UserTripService";
import Dialog from "primevue/dialog";
import editEmployee from "./editEmployee.vue";

export default {
  components: {
    BreadCrumb,
    Form,
    FormField,
    Dialog,
    editEmployee,
  },
  data() {
    return {
      service: new TripService({
        expand: ["employeeListNew"],
      }),
      userService: new UserService(),
      userTripService: new UserTripService(),
      userTripList: {},
      userList: {},
      Str: Str,
      dialogEditDisplay: false,
      dialogAddDisplay: false,
      choosenEmployee: {},
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
    this.userList = await this.userService.allList();
  },
  methods: {
    showEditEmployee(employee) {
      this.choosenEmployee = employee;
      this.dialogEditDisplay = true;
    },
    showAddEmployee() {
      this.dialogAddDisplay = true;
      this.userTripService.tripId = this.service.id;
    },
  },
};
</script>
